import React from "react";
import "./App.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Main from "./components/main/Main";

function App() {
  return (
    <>
      <Main />
    </>
  );
}

export default App;