import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <footer>
      <div className="footer-content">
        <div>
          <h4>Location</h4>
          <p>Bhopal, Madhya Pradesh, India</p>
        </div>
        <div>
          <h4>Email</h4>
          <p>harshitjatav590@gmail.com</p>
        </div>
        <div>
          <h4>Phone</h4>
          <p>9584819267</p>
        </div>
        <div>
          <h4>Social</h4>
          <a target="_blank">
            <i className="fab fa-linkedin-in" />
          </a>
          <a target="_blank">
            <i className="fab fa-twitter" />
          </a>
          <a target="_blank">
            <i className="fab fa-github" />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
