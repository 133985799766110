import React, { useEffect } from "react";
import "../../App.css";
import Typed from "typed.js";
import Services from "../services/Services";
import Skills from "../skills/Skills";
import Projects from "../projects/Projects";
import Contact from "../contact/Contact";
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";

function Main() {
  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector(".navbar");
      const scrollUpBtn = document.querySelector(".scroll-up-btn");

      if (navbar) {
        if (window.scrollY > 20) {
          navbar.classList.add("sticky");
        } else {
          navbar.classList.remove("sticky");
        }
      }

      if (scrollUpBtn) {
        if (window.scrollY > 500) {
          scrollUpBtn.classList.add("show");
        } else {
          scrollUpBtn.classList.remove("show");
        }
      }
    };

    const handleScrollUpClick = () => {
      window.scrollTo({ top: 0, behavior: "auto" });
    };

    const handleMenuItemClick = () => {
      document.documentElement.style.scrollBehavior = "smooth";
    };

    const handleMenuToggle = () => {
      const menu = document.querySelector(".navbar .menu");
      const menuBtnIcon = document.querySelector(".menu-btn i");

      if (menu) menu.classList.toggle("active");
      if (menuBtnIcon) menuBtnIcon.classList.toggle("active");
    };

    window.addEventListener("scroll", handleScroll);

    const scrollUpBtn = document.querySelector(".scroll-up-btn");
    if (scrollUpBtn) {
      scrollUpBtn.addEventListener("click", handleScrollUpClick);
    }

    const menuItems = document.querySelectorAll(".navbar .menu li a");
    menuItems.forEach((item) => {
      item.addEventListener("click", handleMenuItemClick);
    });

    const menuBtn = document.querySelector(".menu-btn");
    if (menuBtn) {
      menuBtn.addEventListener("click", handleMenuToggle);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (scrollUpBtn) {
        scrollUpBtn.removeEventListener("click", handleScrollUpClick);
      }
      menuItems.forEach((item) => {
        item.removeEventListener("click", handleMenuItemClick);
      });
      if (menuBtn) {
        menuBtn.removeEventListener("click", handleMenuToggle);
      }
    };
  }, []);

  useEffect(() => {
    const typedOptions = {
      strings: ["Fullstack Developer", "Software Developer", "Web Developer"],
      typeSpeed: 70,
      backSpeed: 20,
      backDelay: 1500,
      loop: true,
      showCursor: true,
    };

    const typed1 = new Typed(".typing", typedOptions);

    const typed2 = new Typed(".typing-2", typedOptions);

    return () => {
      typed1.destroy();
      typed2.destroy();
    };
  }, []);


  return (
    <div>
      <div className="scroll-up-btn">
        <i className="fas fa-angle-up" />
      </div>

      <Navbar />

      {/* Home */}
      <section className="home" id="home">
        <div className="max-width">
          <div className="home-content">
            <div className="text-1">Hello, This is</div>
            <div className="text-2">Harshit</div>
            <div className="text-3">
              And I'm a <span className="typing" />
            </div>
            {/* <a href="#">Hire me</a> */}
          </div>
        </div>
      </section>

      {/*  About  */}
      <section className="about" id="about">
        <div className="max-width">
          <h2 className="title">About Me</h2>
          <div className="about-content">
            <div className="column left">
              <img src={require("../../Assets/harshit.jpg")} alt="Profile " />
            </div>
            <div className="column right">
              <div className="text">
                I'm Harshit, I'm 21 years old,
                <br />{" "}
                {/* This line break will move "and I am" to a new line */}
                and I'm a <span className="typing-2" />
              </div>
              <p>
                Seeking an entry-level opportunity with an esteemed organization
                where I can utilize my skills &amp; enhance learning in the
                field of work. Capable of mastering new technologies.
              </p>
              <br />
              <div className="text">Why Work With Me</div>
              <p>
                I'm a great communicator &amp; love to invest the necessary time
                to understand the customer's problem very well.
              </p>
              {/* <a target="blank">
                Download CV
              </a> */}
            </div>
          </div>
        </div>
      </section>

      <Services />

      <Skills />

      <Projects />

      <Contact />

      <Footer />
    </div>
  );
}

export default Main;
