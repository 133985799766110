import React, { useState } from "react";
import "../../App.css";

function Navbar() {
  const [menuActive, setMenuActive] = useState(false);

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  const closeMenu = () => {
    setMenuActive(false);
  };

  return (
    <nav className="navbar">
      <div className="max-width">
        <div className="logo">
          <a>
            <span>whoisharsh</span>
            <span>.in</span>
          </a>
        </div>
        <ul className={`menu ${menuActive ? "active" : ""}`}>
          <li>
            <a href="#home" className="menu-btn" onClick={closeMenu}>
              <strong>Home</strong>
            </a>
          </li>
          <li>
            <a href="#about" className="menu-btn" onClick={closeMenu}>
              <strong>About</strong>
            </a>
          </li>
          <li>
            <a href="#services" className="menu-btn" onClick={closeMenu}>
              <strong>Services</strong>
            </a>
          </li>
          <li>
            <a href="#skills" className="menu-btn" onClick={closeMenu}>
              <strong>Skills</strong>
            </a>
          </li>
          <li>
            <a href="#projects" className="menu-btn" onClick={closeMenu}>
              <strong>Projects</strong>
            </a>
          </li>
          <li>
            <a href="#contact" className="menu-btn" onClick={closeMenu}>
              <strong>Contact</strong>
            </a>
          </li>
        </ul>
        <div className="menu-btn" onClick={toggleMenu}>
          <i className={`fas fa-bars ${menuActive ? "active" : ""}`} />
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
