import React from "react";
import "../../App.css";

function Skills() {
  return (
    <section className="skills" id="skills">
      <div className="max-width">
        <h2 className="title">My Skills</h2>
        <div className="skills-content">
          <div className="column left">
            <br />
            <div className="text">PROJECT</div>
            <p></p>
            <li className="project">Portfolio (React.js)</li>
            <li className="project">
              MedHub HealthCare Hospital(React.js, PostgreSQL)
            </li>
            <li className="project">
              ShopFusion - eCommerce Website (Like Amazon, Flipkart etc.)
            </li>
            <li className="project">Fully Responsive Design</li>
            <li className="project">
              Currently Working on a ShopFusion website (Like Flipkart)
            </li>
            <p />
            <br />
            <div className="text">DEVELOPMENT SKILLS</div>
            <p>
              I'm familiar & work on a daily basis with HTML, CSS, JavaScript,
              Bootstrap, React js, next.js and other modern frameworks.
            </p>
            {/* <a href="https://aistechx.com/" target="blank">
              Get Educate...
            </a> */}
          </div>
          <div className="column right">
            <div className="bars">
              <div className="info">
                <span>Figma</span>
                <span>85%</span>
              </div>
              <div className="line figma" />
            </div>

            <div className="bars">
              <div className="info">
                <span>HTML</span>
                <span>70%</span>
              </div>
              <div className="line html" />
            </div>

            <div className="bars">
              <div className="info">
                <span>CSS</span>
                <span>65%</span>
              </div>
              <div className="line css" />
            </div>

            <div className="bars">
              <div className="info">
                <span>JavaScript</span>
                <span>70%</span>
              </div>
              <div className="line js" />
            </div>

            <div className="bars">
              <div className="info">
                <span>React.js</span>
                <span>70%</span>
              </div>
              <div className="line react" />
            </div>

            <div className="bars">
              <div className="info">
                <span>Next.js</span>
                <span>65%</span>
              </div>
              <div className="line next" />
            </div>

            <div className="bars">
              <div className="info">
                <span>Node.js</span>
                <span>65%</span>
              </div>
              <div className="line node" />
            </div>
            {/* <div className="bars">
              <div className="info">
                <span>Bootstraps</span>
                <span>60%</span>
              </div>
              <div className="line bootstraps" />
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Skills;
