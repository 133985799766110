import React from 'react'
import '../../App.css';

function Services() {
  return (
    <section className="services" id="services">
    <div className="max-width">
      <h2 className="title">My services</h2>
      <div className="serv-content">
        <div className="card">
          <div className="box">
            <i className="fa-solid fa-laptop-code"></i>
            <div className="text">Web Development</div>
            <p>
              As a Full Stack Developer, I specialize in creating dynamic and responsive web applications. I have experience with Shopify Dropshipping Stores, high-converting eCommerce platforms, and custom code solutions.
            </p>
          </div>
        </div>
        <div className="card">
          <div className="box">
            <i className="fas fa-user-secret" />
            <div className="text">Data Security</div>
            <p>
              I ensure that sensitive data is securely managed and stored outside of the project folder. User Secrets are handled with care, although they are not encrypted.
            </p>
          </div>
        </div>
        <div className="card">
          <div className="box">
            <i className="fas fa-code" />
            <div className="text">App Design</div>
            <p>
              I focus on both user interface (UI) and user experience (UX) design. This includes the overall style of the app, such as color schemes, font choices, and the design of buttons and widgets.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Services
