import React from "react";
import Swal from "sweetalert2";
import emailjs from "emailjs-com";
import "../../App.css";

function Contact() {
    
  const handleFormSubmit = (event) => {
    event.preventDefault();

    emailjs
      .sendForm(
        "service_p99jjff",
        "template_3fx8a42",
        event.target,
        "K0T_zqwZD-ysm8Lwe"
      )
      .then(
        (result) => {
          console.log(result.text);
          Swal.fire({
            icon: "success",
            title: "Email Sent",
            text: "Your message has been sent successfully!",
          });
        },
        (error) => {
          console.log(error.text);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        }
      );
  };

  return (
    <section className="contact" id="contact">
      <div className="max-width">
        <h2 className="title">Contact Me</h2>
        <div className="contact-content">
          <div className="column left">
            <div className="text">Get in Touch</div>
            <p>
              Feel free to get in touch with me. I am always open to discussing
              new projects, creative ideas or opportunities to be a part of your
              visions. Email me with any questions or inquiries. I'll happy to
              answer your questions and set up a meeting with you.
            </p>
            <div className="icons">
              <a></a>
              <div className="row">
                <a>
                  <i className="fa-solid fa-user-large" />
                </a>
                <div className="info">
                  <a>
                    <div className="head">Name</div>
                  </a>
                  <div className="sub-title">
                    <a />
                    <a style={{ textDecoration: "none", color: "#333" }}>
                      {" "}
                      Harshit Jatav
                    </a>
                  </div>
                </div>
              </div>
              <div className="row">
                <i className="fa-solid fa-location-dot" />
                <div className="info">
                  <div className="head">&nbsp;Address</div>
                  <div className="sub-title">
                    &nbsp;Bhopal, Madhya Pradesh, India
                  </div>
                </div>
              </div>
              <div className="row">
                <i className="fa-solid fa-envelope" />
                <div className="info">
                  <div className="head">Email</div>
                  <div className="sub-title">
                    <a
                      href="mailto:#"
                      style={{ textDecoration: "none", color: "#333" }}
                    >
                      {" "}
                      harshitjatav590@gmail.com
                    </a>
                  </div>
                </div>
              </div>
              <div className="row">
                <i className="fa-solid fa-language" />
                <div className="info">
                  <div className="head">Language Known</div>
                  <div className="sub-title">English, Hindi</div>
                </div>
              </div>
              <br />
              {/* Social */}
              <div className="social-menu">
                <ul>
                  <li>
                    <a>
                      <i className="fa-brands fa-github" />
                    </a>
                  </li>
                  <li>
                    <a>
                      <i className="fa-brands fa-linkedin-in" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/_w3b_harsh_/">
                      <i className="fa-brands fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a>
                      <i className="fa-brands fa-twitter" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* Reach */}
          <div className="column right">
            <div className="text">Message me</div>
            <form action="#" onSubmit={handleFormSubmit}>
              <div className="fields">
                <div className="field name">
                  <input
                    type="text"
                    name="from_name"
                    placeholder="Your Name"
                    required
                  />
                </div>
                <div className="field email">
                  <input
                    type="email"
                    name="user_email"
                    placeholder="Your Email"
                    required
                  />
                </div>
              </div>
              <div className="field">
                <input
                  type="text"
                  name="user_subject"
                  placeholder="Subject"
                  required
                />
              </div>
              <div className="field textarea">
                <textarea
                  name="message"
                  cols={30}
                  rows={10}
                  placeholder="Message.."
                  required
                ></textarea>
              </div>
              <div className="button-area">
                <button type="submit">Send message</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
